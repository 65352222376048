import React from 'react';
import { motion } from 'framer-motion';
import Button from '../../components/Button/Button';

const MapModal = ({ isOpen, onClose, src }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.3 }}
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full text-black h-5/6 flex flex-col justify-evenly"
      >
        <div className="w-full h-5/6">
          {/* Aquí va el mapa de Google Maps */}
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3861.9676419967686!2d-90.42165331586914!3d14.54384314106968!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589bbcc7359ba61%3A0x3f93159c086a3df1!2sArika%20Venue!5e0!3m2!1sen!2sgt!4v1723530219542!5m2!1sen!2sgt"
            className="w-full h-full"
            style={{ border: 0 }}
            aria-hidden="false"
            tabIndex="0"
          />
          </div>
          <Button
            onClick={()=>onClose()}
           >
            Cerrar
          </Button>
        </motion.div>
        </div>
  );
};

export default MapModal;
