import React from 'react'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export default function AnimatedText({children}) {
    const { ref, inView } = useInView({
      triggerOnce: false, // Animar solo la primera vez que el elemento entra en vista
    });
  
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 1, x: '-50vw' }}  // Empieza fuera de la pantalla
        animate={inView ? { opacity: 1, x: '0vw' } : {}} // Animar cuando entra en vista
        transition={{ duration: 1.2 }} // Duración de la animación
      >
        {children}
      </motion.div>
    );
}
