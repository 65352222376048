import React from 'react'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export default function ZoomOutText({children}) {
    const { ref, inView } = useInView({
      triggerOnce: false, // Animar solo la primera vez que el elemento entra en vista
    });
  
    return (
      <motion.div
        ref={ref}
        initial={{ scale: 0 }}  // Empieza fuera de la pantalla
        animate={inView ? { scale: 1} : {}} // Animar cuando entra en vista
        transition={{ duration: 1.5 }} // Duración de la animación
      >
        {children}
      </motion.div>
    );
}
