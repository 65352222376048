import React, { useEffect, useState } from 'react'
import HeaderContainer from '../layouts/HeaderContainer/HeaderContainer'
import AnimatedText from '../components/Animations/AnimatedText/AnimatedText'
import SeparatorContainer from '../layouts/SeparatorContainer/SeparatorContainer'
import ZoomOutText from '../components/Animations/ZoomOutText/ZoomOutText'
import FlatContainer from '../layouts/FlatContainer/FlatContainer'
import { ModalContainer } from '../layouts/ModalContainer/ModalContainer'
import OverlayContainer from '../layouts/OverlayContainer/OverlayContainer'
import ImageContainer from '../layouts/ImageContainer/ImageContainer'
import { useParams } from 'react-router-dom'
import RoundImage from '../components/Image/RoundImage'
import MapModal from '../layouts/MapModal/MapModal'
import Button from '../components/Button/Button'
import { sendWhatsappMessage } from '../helpers/helpers'

export default function Karen() {
    const { name } = useParams();
    const [invited, setInvited] = useState('Invitación no válida');
    const [invitedAmount, setInvitedAmount] = useState('N/A');

    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('/invitadous.csv');
            const text = await response.text();
            const rows = text.split('\n').map(row => row.split(','));
            setCsvData(rows);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (csvData.length > 0) {
            const result = csvData.find(row => row[0] === name);
            if (result) {
                setInvited(result[1]);
                setInvitedAmount(result[2]);
            }
        }
    }, [csvData, name]);

    const handleWazeButtonClick = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        let url;
        console.log(userAgent);
        if (/android/i.test(userAgent)) {
          // Dispositivo Android¿
          url = 'waze://?ll=14.543264,-90.421381&navigate=yes';
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          // Dispositivo iOS
          url = 'waze://?ll=14.543264,-90.421381&navigate=yes';
        } else {
          // PC o navegador web
          url = 'https://www.waze.com/es/live-map/directions/la-finka-guatemala-rn-18?place=w.176685201.1766720942.15423576';
        }

        window.open(url, '_blank');
    };

    const [isModalOpen, setModalOpen] = useState(false);
    const [isMapModalOpen, setMapModalOpen] = useState(false); 
    return (
        <>
            <HeaderContainer>
                <div className='bg-black bg-opacity-35 py-5'>
                    <ZoomOutText><div className='text-6xl highlight-text'>José y Karen</div></ZoomOutText>
                    <ZoomOutText><div className='text-4xl pt-10 highlight-text'>Boda Civil</div></ZoomOutText>
                    <ZoomOutText><div className='text-2xl pt-5'>7-9-24 - 11:00 AM</div></ZoomOutText>   
                </div>
            </HeaderContainer>
            <SeparatorContainer type='inverted'>
                <AnimatedText><div className='text-2xl'>Es un honor para nosotros:</div></AnimatedText>
                <div className='flex flex-row my-5'>
                    <div className='w-1/2 text-center self-center'>
                        <ZoomOutText><div className='text-4xl self-center highlight-text'>José Miguel Morán Cordova</div></ZoomOutText>
                    </div>
                    <div className='w-1/2 text-center self-center'>
                        <ZoomOutText><div className='text-4xl self-center highlight-text'>Karen Denisse Menéndez Tobías</div></ZoomOutText>
                    </div>
                </div>
                <AnimatedText><div className='text-2xl'>{invitedAmount == 1 ? 'Invitarte' : 'Invitarlos'} a nuestra boda</div></AnimatedText>
            </SeparatorContainer>
            <SeparatorContainer>
                <AnimatedText><div className='text-xl'>Es un gusto que nos acompañe{invitedAmount == 1 ? 's' : 'n'}</div></AnimatedText>
                <ZoomOutText><div className='text-4xl py-5 highlight-text'>{invited}</div></ZoomOutText>
            </SeparatorContainer>
            <ImageContainer>
                <OverlayContainer>
                    <AnimatedText>
                        <div className='text-6xl pb-10  highlight-text'>Ubicación</div>
                    </AnimatedText>
                    <AnimatedText>
                        <div className='text-3xl'>Arika Venue</div>
                        <div className='text-2xl'>km 20.7 carretera a San José Pinula</div>
                    </AnimatedText>
                    <div className='flex flex-row justify-center'>
                    <ZoomOutText>
                        <RoundImage src={'/Arika.png'} alt={'Arika Venue'} /> 
                    </ZoomOutText>
                    </div>
                    <AnimatedText>
                        <div className='text-3xl py-2'>7 de Septiembre</div>
                        <div className='text-3xl pb-2'>11:00 AM</div>
                    </AnimatedText>
                    <div className='flex flex-row justify-evenly pb-4'>
                        <ZoomOutText>
                            <Button
                                onClick={() => setMapModalOpen(true)}
                            >
                                <i class="fa-regular fa-map"></i> Mapa
                            </Button>
                        </ZoomOutText>
                        <ZoomOutText>
                            <Button
                                onClick={() => handleWazeButtonClick()}
                            >
                                <i class="fa-brands fa-waze"></i> Waze
                            </Button>
                        </ZoomOutText>
                    </div>
                </OverlayContainer>
            </ImageContainer>
            <SeparatorContainer>
                <AnimatedText><div className='text-3xl'>Invitación válida para {invitedAmount} persona{invitedAmount > 1 ? 's' : ''}</div></AnimatedText>
            </SeparatorContainer>
            <FlatContainer>
                <AnimatedText>
                    <div className='text-6xl text-black pb-10  highlight-text'>Vestuario</div>
                </AnimatedText>
                <ZoomOutText>
                    <div className="flex flex-row justify-center">
                        <div ><img src="/suite2.svg" className="dress" /></div>
                        <div ><img src="/dress2.svg" className="dress" /></div>
                    </div>
                </ZoomOutText>
                <AnimatedText>
                    <div className='text-3xl text-black  highlight-text'>Formal</div>
                </AnimatedText>
            </FlatContainer>
            <ImageContainer image='1'>
                <OverlayContainer>
                    <AnimatedText>
                        <div className='text-6xl pb-10 highlight-text'>Contacto</div>
                        <div className='text-2xl'>¡Envía tu confirmación de asistencia y buenos deseos a los novios!</div>
                    </AnimatedText>
                    <div className='flex flex-row justify-evenly'>
                        <ZoomOutText>
                            <div className='flex flex-col justify-center px-2'>
                                <div className='text-3xl mb-4  highlight-text'>José</div>
                                <Button
                                    onClick={() => sendWhatsappMessage('50241509718', 'Hola José!')}
                                >
                                    <i class="fa-brands fa-whatsapp"></i> 4150-9718 
                                </Button>
                            </div>
                        </ZoomOutText>
                        <ZoomOutText>
                            <div className='flex flex-col justify-center px-2'>
                                <div className='text-3xl mb-4  highlight-text'>Karen</div>
                            <Button
                                    onClick={() => sendWhatsappMessage('50250184790', 'Hola Karen!')}
                            >
                                <i class="fa-brands fa-whatsapp"></i> 5018-4790
                            </Button>
                            </div>
                        </ZoomOutText>
                    </div>
                </OverlayContainer>
            </ImageContainer>
            <SeparatorContainer>
                <AnimatedText><div className='text-4xl'>¡Es un gusto que puedas acompañarnos!</div></AnimatedText>
            </SeparatorContainer>

            <ModalContainer isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
            <MapModal isOpen={isMapModalOpen} onClose={() => setMapModalOpen(false)} />
        </>
    )
}
