import React, { useEffect, useState } from 'react'
import './ImageContainer.css'
import Fondo from '../../assets/images/KarenCanche2.jpg' 
import Fondo2 from '../../assets/images/KarenCanche_.jpg'

export default function ImageContainer({children, image='0'}) {
  const [background, setbackground] = useState(Fondo)

  useEffect(() => {
    if (image === '1')
      setbackground(Fondo2) 
  }, [image])

  return (
    <div className='h-screen w-full place-content-center bg-cover bg-center '
  style={{ backgroundImage: `url(${background})` }}>
        {children}
    </div> 
  )
}
