import React from 'react'
import './HeaderContainer.css'
import Fondo from '../../assets/images/KarenCanche.jpg' 

export default function HeaderContainer({children}) {
  return (
    <div className="h-screen w-full place-content-end text-center bg-cover bg-center image-bg pb-20" 
  style={{ backgroundImage: `url(${Fondo})` }}>
        {children}
    </div> 
  )
}
