import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Karen from './pages/Karen';

function App() {
  return (
    <Router>
        <Routes>
            <Route path="/:name" element={<Karen />} />
            <Route path="/" element={<Karen />} />
        </Routes>
    </Router>
  );
}

export default App;
