import React from 'react'

export default function RoundImage({ src, alt }) {
  return (
    <div className="w-48 h-48 md:w-48 md:h-48 rounded-full overflow-hidden bg-gray-200">  
      <img
        src={src}
        alt={alt}
        className="object-cover w-full h-full"
      />
    </div>
  )
}
