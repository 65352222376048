import React from 'react'
import './OverlayContainer.css'

export default function OverlayContainer({children}) {
  return (
    <div className='overlays text-center place-content-evenly flex flex-col px-2 py-2'>
        {children}
    </div> 
  )
}
