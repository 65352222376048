import React from 'react';
import { motion } from 'framer-motion';
import Button from '../../components/Button/Button';

export function ModalContainer({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.3 }}
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full text-black"
      >
        <h2 className="text-2xl font-bold mb-4">Modal Title</h2>
        <p className="mb-4">This is a modal with smooth animations using Framer Motion and Tailwind CSS.</p>
        <Button
          onClick={()=>onClose()}
        >
          Close
        </Button>
      </motion.div>
    </div>
  );
}